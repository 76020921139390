<template>
  <div style="padding-top: 20px">
    <h2>
      Roses are red, you're the most beautiful girl I ever saw, let's dance on a
      boat together to Ratata :)
    </h2>
    <iframe
      width="250pxvw"
      height="200px"
      src="https://www.youtube.com/embed/xkejbXejA-0?autoplay=1"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;
      picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
    <h2 style="padding: 20px">Come with me to FY23 Prom on July 20th?</h2>
    <div>
      <img
        style="width: 90vw; max-width: 900px"
        src="../assets/FiscalPromScreenshot.png"
      />
    </div>
    <a href="https://fb.me/e/16Nf1VgX9"><h2>Facebook Invite Link</h2></a>
  </div>
</template>

<script>
export default {};
</script>
